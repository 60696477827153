import { bindActionCreators } from 'redux';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createReduxStore } from './common/store/create-redux-store';
import { decorateActionsWithLogger } from '~commons/lib/monitoring';
import { controllerActions } from './controller/controller-actions';
import { getEnvParams } from '~commons/get-env-params';

const createController: CreateControllerFn = async (controllerParams) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  return {
    async pageReady() {
      const flowAPI = controllerParams.flowAPI;
      const { setProps, wixCodeApi } = flowAPI.controllerConfig;
      const { isDebug, logNetwork } = getEnvParams(wixCodeApi);
      const store = createReduxStore(setProps);

      const actions = decorateActionsWithLogger(
        bindActionCreators(controllerActions, store.dispatch),
        flowAPI.errorMonitor,
        isDebug || logNetwork === 'error',
      );
      setProps({ state: store.getState(), actions });
    },
  };
};

export default createController;
