
  import userController from '/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/components/CollectionWidget/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://cd2c8e8d2a11492b9ae615ed62ef6072@sentry.wixpress.com/3606',
    id: 'ddac5f9ae34511ec9fff0242ac12001c',
    projectName: 'wix-reviews',
    teamName: 'forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["todo-viewer-apps-95a5fce-e370-4402-9ce4-96956acc747d"],"centralized":true};

  var translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = {"enableUniversalEvents":true};

  var defaultTranslations = {"field-error.invalid-email":"This email is not valid","field-error.required.email":"Enter an email address, like example@email.com","field-error.required.rating":"Select a star rating.","field-error.required.body":"Write your review.","field-error.required.title":"Write a title for your review.","field-error.required.media":"Add at least one image or video.","field-error.media-still-uploading":"Media is still uploading","field-error.required.name":"Enter a reviewer's name.","field-label.required":"This field is required.","field-label.name":"Name{required, select, true {*} other {}} (this appears publicly)","field-label.email":"Email{required, select, true {*} other {}} (this will be kept private)","field-label.add-images":"Add images{required, select, true {*} other {}} ({count}/{limit})","field-label.add-videos":"Add videos{required, select, true {*} other {}} ({count}/{limit})","field-label.add-images-videos":"Add images & videos{required, select, true {*} other {}} ({count}/{limit})","review-form.aria-label-edit":"Edit review","review-form.aria-label-create":"Leave a review","close-button.aria-label":"Close","review-form.cancel":"Cancel","review-form.update":"Update","review.media-aria-label":"Media","media-error.template":"Could not upload {filename}: {message}","media-error.generic":"Temporary technical issue","review.media-thumbnail-aria-label":"Media file {filename}","empty-state.list-loading-text":"Loading…","review.media-input-remove-button":"Remove","clear-button.aria-label":"Clear","field-label.title.default":"Review title","field-label.body.default":"Review","review-form.publish.default":"Publish","top-section.cta.default":"Leave a Review","field-label.star-rating.default":"Add a rating"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Reviews",
    componentName: "CollectionWidget",
    appDefinitionId: "a95a5fce-e370-4402-9ce4-96956acc747d",
    componentId: "d79273d8-cd93-479f-8818-4f485b1e0c84",
    projectName: "wix-reviews",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "components/CollectionWidget/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/components/CollectionWidget/controller.ts",
  };

  export const controller = _controller
  export default controller;
