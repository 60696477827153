import { createStore } from 'redux';
import { rootReducer } from './reducer';
import { State } from './types';

export const createReduxStore = (listener: (state: State) => void) => {
  const store = createStore(rootReducer);
  let oldState: State;
  store.subscribe(() => {
    const newState = store.getState();
    if (oldState === newState) {
      return;
    }
    oldState = newState;
    listener(newState);
  });
  return store;
};
